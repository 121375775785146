<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Which of the following items are considered acceptable to wear in the General Chemistry
        Laboratories (check all 19 that apply)?
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemOleMiss116SSQ0_Q17',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: 'shorts', value: 'shorts'},
        {text: 'leggings', value: 'leggings'},
        {text: 'capris', value: 'capris'},
        {text: 'baseball pants', value: 'baseballPants'},
        {text: 'pajama pants', value: 'pajamaPants'},
        {text: 'sweat pants', value: 'sweatPants'},
        {text: 'scrubs', value: 'scrubs'},
        {text: 'closed-toe shoes', value: 'closedToeShoes'},
        {text: 'tennis shoes', value: 'tennisShoes'},
        {text: 'ballet slippers', value: 'balletSlippers'},
        {text: 'combat boots', value: 'combatBoots'},
        {text: 'Birkenstocks', value: 'birkenstocks'},
        {text: 'long earrings', value: 'longEarrings'},
        {text: 'nose rings', value: 'noseRings'},
        {text: 'directly vented goggles', value: 'directlyVentedGoggles'},
        {text: 'indirectly vented goggles', value: 'indirectlyVentedGoggles'},
        {text: 'pajama tops', value: 'pajamaTops'},
        {text: 'sweat shirts', value: 'sweatShirts'},
        {text: 'hoodies', value: 'hoodies'},
        {text: 'long-sleeved shirts', value: 'longSleevedShirts'},
        {text: 'short-sleeved shirts', value: 'shortSleevedShirts'},
        {text: 'tank tops', value: 'tankTops'},
        {text: 'short socks', value: 'shortSocks'},
        {text: 'tall socks', value: 'tallSocks'},
        {text: 'belts', value: 'belts'},
        {text: 'wired earbuds', value: 'wiredEarbuds'},
        {text: 'bluetooth earbuds', value: 'bluetoothEarbuds'},
        {text: 'long necklaces', value: 'longNecklaces'},
        {text: 'choker necklaces', value: 'chokerNecklaces'},
        {text: 'eye glasses', value: 'eyeGlasses'},
        {text: 'contact lenses', value: 'contactLenses'},
        {text: 'crop tops', value: 'cropTops'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
